import { roles } from '@/store/Auth';

export default [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/views/LoadingView.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    // The id is a optional parameter due to
    path: '/type-registration/:id?',
    name: 'type-registration',
    component: () => import('@/views/TypeRegistration.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: roles.Boatbuilder,
    },
  },
  {
    // The id is a optional parameter due to
    path: '/type-registration-success/',
    name: 'type-registration-success',
    component: () => import('@/views/TypeRegistrationSuccess.vue'),
    meta: {
      requiresAuth: true,
      permittedRole: roles.Boatbuilder,
    },
  },
  {
    path: '/noise-registration/:id?',
    name: 'noise-registration',
    component: () => import('@/views/NoiseRegistration.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: roles.Boatbuilder,
    },
  },
  {
    path: '/reorder-registration/:id?',
    name: 'reorder-registration',
    component: () => import('@/views/ReorderRegistration.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: roles.Boatbuilder,
    },
  },
  {
    path: '/reorder-registration-success/:typeCertificateId',
    name: 'reorder-registration-success',
    component: () => import('@/views/ReorderRegistrationSuccess.vue'),
    meta: {
      requiresAuth: true,
      permittedRole: roles.Boatbuilder,
    },
  },
  {
    path: '/type-approval/:id?',
    name: 'type-approval',
    component: () => import('@/views/TypeApproval.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: [roles.Expert, roles.TypeApprovalBody, roles.NavigationDepartment],
    },
  },
  {
    path: '/noise-approval/:id?',
    name: 'noise-approval',
    component: () => import('@/views/NoiseApproval.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: [roles.Expert, roles.TypeApprovalBody, roles.NavigationDepartment],
    },
  },
  {
    path: '/type-certficate/:id/attachments',
    name: 'type-certificate-attachments',
    component: () => import('@/views/TypeCertificateAttachments.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: [roles.Expert, roles.TypeApprovalBody, roles.Boatbuilder, roles.NavigationDepartment],
    },
  },
  {
    path: '/type-certficate/:id/images',
    name: 'type-certificate-images',
    component: () => import('@/views/TypeCertificateImages.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/type-certficate/:id/documents',
    name: 'type-certificate-documents',
    component: () => import('@/views/TypeCertificateDocuments.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/type-certficate/:id/motors',
    name: 'type-certificate-motors',
    component: () => import('@/views/TypeCertificateMotors.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
