import { defineStore } from "pinia";

export const useLoadingStore = defineStore('LoadingSate', {
  state: () => {
    return {
      isLoading: false,
    }
  },
  actions: {
    setLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
  },
});
