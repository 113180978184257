import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes.js';
import { useAuthStore } from '@/store/Auth';
import { getCurrentUser } from '@/services/auth';

let initial = true;
let auth;

const router = createRouter({
  history: typeof window !== 'undefined' ? createWebHistory(import.meta.env.BASE_URL) : {}, // Protect history definition for ssr-build of styleguide
  routes,
});

/**
 * Check if the user is logged in
 *
 * @param {object} to The route to navigate to
 */
const checkUserLoggedIn = (to) => {
  return !to.meta.requiresAuth || auth.isLoggedIn;
};

/**
 * Check if the user is permitted to access the route
 * 
 * @param {object} to The route to navigate to
 */
const checkUserPermitted = (to, auth) => {
  if (to.meta.permittedRoles && !to.meta.permittedRoles.includes(auth.selectedRole)) {
    return false;
  }
  return !to.meta.permittedRole || auth.selectedRole == to.meta.permittedRole;
}

router.beforeEach(async (to, from, next) => {
  const goToPage = (routeName) => {
    return next({ name: routeName });
  };

  if (!auth) auth = useAuthStore();
  // Check if user is logged in on initial load
  if (initial) {
    initial = false;
    /* eslint-disable no-empty */
    try {
      const user = await getCurrentUser();
      auth.setUser(user.data);
    } catch { }
    /* eslint-enable no-empty */
  }

  if (!checkUserLoggedIn(to)) {
    return goToPage('login');
  }

  if (to.name === 'login' && auth.isLoggedIn) {
    return goToPage('dashboard');
  }

  if (!checkUserPermitted(to, auth)) {
    return goToPage('dashboard');
  }

  return next();
});


export default router;
