import loadingSVG from '@/assets/icons/loading.svg?url';

const addLoading = (el) => {
  el.classList.add('loading-directive__container');

  el.insertAdjacentHTML(
    'afterbegin',
    `<div class="loading-directive">
      <div>
        <div>
          <img src="${loadingSVG}" alt="Loading" />
        </div>
      </div>
    </div>`
  );
};

const removeLoading = (el) => {
  el.classList.remove('loading-directive__container');
  el.querySelectorAll('.loading-directive').forEach((elem) => {
    // elem.remove is not supported in IE11 so we use removeChild on the parent element
    elem.parentNode.removeChild(elem);
  });
};

/**
 * Toggle the loader
 *
 * @param {*} el
 * @param {*} binding
 */
const toggleLoading = (el, binding) => {
  const value = binding.value;

  if (value) {
    addLoading(el);
  } else {
    removeLoading(el);
  }
};

const loadingDirective = {
  mounted(el, binding) {
    toggleLoading(el, binding);
  },
  updated(el, binding) {
    toggleLoading(el, binding);
  },
  beforeUnmount(el) {
    removeLoading(el);
  },
};

export default loadingDirective
