import { defineStore } from "pinia";

export const roles = {
  Boatbuilder: 'Boatbuilder',
  Expert: 'Expert',
  BusinessOffice: 'Business Office',
  NavigationDepartment: 'Navigation Department',
  TypeApprovalBody: 'Type Approval Body',
};

export const useAuthStore = defineStore("Auth", {
  state: () => {
    return {
      firstName: null,
      lastName: null,
      email: null,
      roles: [],
      selectedRole: localStorage.getItem('selectedRole') || '',
      isTwoFactorActive: false,
    };
  },
  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn: (state) => state.email !== null,

    /**
     * check if user has role as Boatbuilder
     * @param {object} state Module state
     */
    isBoatbuilder: (state) => state.selectedRole == roles.Boatbuilder,
    isExpert: (state) => state.selectedRole == roles.Expert,
    isBusinessOffice: (state) => state.selectedRole == roles.BusinessOffice,
    isNavigationDepartment: (state) => state.selectedRole == roles.NavigationDepartment,
    isTypeApprovalBody: (state) => state.selectedRole == roles.TypeApprovalBody,
  },
  actions: {
    /**
     * Remove the login information and effectively log the user out
     * @param {object} state Module state
     */
    removeAuth() {
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.roles = [];
      this.isTwoFactorActive = false;
      this.selectedRole = ""
    },

    /**
     * Set the user's (real) name
     * @param {object} state Module state
     * @param {object} Object containing first and last name
     */
    setUser(user) {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.roles = user.roles;
      if (user.roles.length > 0 && !user.roles.includes(this.selectedRole)) {
        this.selectedRole = user.roles[0]
        localStorage.setItem('selectedRole', this.selectedRole)
      }
    },

    /**
     * Set  role of active user
     * @param {*} state Module state
     * @param {*} role value
     */
    setRole(role) {
      this.selectedRole = role
      localStorage.setItem('selectedRole', role)
    },

    /**
     * Set the two factor active value
     * @param {*} state Module state
     * @param {*} twoFactorActive twoFactorActive value
     */
    setIsTwoFactorActive(twoFactorActive) {
      this.isTwoFactorActive = twoFactorActive;
    },
  },
});
