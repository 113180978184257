<template>
  <div class="bg-gray-200 app-master">
    <LoadingSpinner v-if="loadingStore.isLoading" />
    <router-view />
    <notification />
  </div>
</template>

<script setup>
import Notification from '@/components/Notification.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { setLanguage } from '@/services/language.service';
import { useLoadingStore } from '@/store/Loading';

const loadingStore = useLoadingStore();

setLanguage('de')
</script>

<style>
.app-master {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}
</style>
