import { userApi, userApiUnprotected } from './api';
import { parseToCamel, parseToSnake } from '@/helpers/transformCase'

/**
 * Perform a login
 *
 * @return User Info
 */
const login = async (email, password, twoFactorToken, type) => {
  const response = await userApiUnprotected.post('account/login', {
    email: email,
    password: password,
    token: twoFactorToken ? twoFactorToken : null,
    type: type ? type : null,
  });

  return parseToCamel(response);
};

const forgotPassword = async (email) => {
  const response = await userApiUnprotected.post('account/reset-password/email', {
    email: email,
  });

  return response.data;
};

const checkResetToken = async (token, email) => {
  const response = await userApiUnprotected.post('account/reset-password/check-token', {
    token: token,
    email: email,
  });

  return response.data;
};

const resetPassword = async (password, token, email) => {
  try {
    const response = await userApiUnprotected.post('account/reset-password/reset', {
      password: password,
      token: token,
      email: email,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

const getCurrentUser = async () => {
  const response = await userApi.get('account/me');

  return parseToCamel(response);
};

const logout = async () => {
  const response = await userApi.post('account/logout');
  return response;
};

const getUserProfile = async () => {
  const response = await userApi.get('account/profile');
  return parseToCamel(response.data);
}

const updateUserProfile = async (user) => {
  const response = await userApi.put('account/profile', parseToSnake(user));
  return parseToCamel(response.data);
}

export {
  login,
  forgotPassword,
  checkResetToken,
  resetPassword,
  getCurrentUser,
  logout,
  getUserProfile,
  updateUserProfile,
};
